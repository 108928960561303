import React, { useState, useContext, useEffect } from 'react';
import { useParams, Link } from "react-router-dom"

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faCartPlus } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import CartCtx from './cart'
import { TplWSidebar as Tpl } from '#Template'
import Button from '#Button'
import Breadcrumbs from './Breadcrumbs'
import CreateDial from './CreateDial'
import { DisplayNumber } from '#Display'
import ProductBinds from './ProductBinds'

export default function QuoteAccessoryView() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const { _id } = useParams()
	const [ data, setData ] = useState({})

	const handleAddToCart = () => cart.chQuantity({ product:_id, delta:1 })
	const handleRefresh = () => { api.call('accessory/get/'+_id).then(setData) }
	useEffect(handleRefresh, [ _id ])

	const name = data?.name ? (data.name + (data.active ? '' : ' [NON ATTIVO]')) : ''
	const rw = api.chkAuth('quote-editor')
	return (
		<Tpl title='Scheda accessorio'>
			<Breadcrumbs accessory={_id} />
			<Typography variant="h4" gutterBottom component="div">
				{ rw && (
					<IconButton component={Link} to={"/quote/accessory/edit/"+_id}>
						<FontAwesomeIcon icon={faEdit} />
					</IconButton>
				)}
				{name}
			</Typography>
			{ data.code && (
				<Typography variant="subtitle1" gutterBottom component="div">
					Codice: {data.code}
				</Typography>
			)}

			<Box sx={{ mb:3 }}>
				{ data.description && data.description.split("\n").map(descriptionBlock => (
					<Typography variant="body1">
						{descriptionBlock}
					</Typography>
				))}
				{ Boolean(data.price) && (
					<Typography variant="h6" gutterBottom component="div" sx={{ mt:1 }}>
						Prezzo: <DisplayNumber suffix="€" value={data.price} />
					</Typography>
				)}
				{ api.chkAuth('quote-trader') && <Button icon={faCartPlus} label="Aggiungi al carrello" onClick={handleAddToCart} /> }
			</Box>

			<ProductBinds accessory={_id} />
			<CreateDial />
		</Tpl>
	)
}