import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

export default function LostOfferReasonEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' voce'
	const formDef = {
		callSet: [ "lost-offer-reason/set", _id ],
		callGet: _id && "lost-offer-reason/get/"+_id,
		idRedirect: _id => '/lost-offer-reason/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/lost-offer-reason/list">
			<Form {...formDef}>
				<Input label="Definizione" name="label" required />
			</Form>
		</Tpl>
	)
}