import React, { useState, useContext, useEffect } from 'react';

import { faCog, faClone, faEdit, faFilePdf, faMagnifyingGlass, faNote, faTrash } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'

const tabDef = [
	{ label:"Nome", field:'name' },
	{ label:"Attivo?", field:'active', type:'bool' },
]

export default function LostOfferReasonList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handlePreviewPdf = ({ name }) => api.openFile({ tag:'datasheet/get-binary', params:{ name } })
	const handleDownloadPdf = ({ name }) =>
		api.openFile({
			tag:'datasheet/get-binary',
			params:{ name },
			download: name + '.pdf',
		})
	const handleRefresh = () => api.call('datasheet/list', { showInactive:true }).then(setIds)
	useEffect(() => { handleRefresh() }, [])

	const getRow = name => api.call('datasheet/get', { name })
	const defBtns = [
		{ icon:faMagnifyingGlass, label:'Anteprima PDF', onClick:handlePreviewPdf },
		{ icon:faFilePdf, label:'Scarica PDF', onClick:handleDownloadPdf },
	]

	return (
		<Tpl title='Lista schede tecniche'>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
		</Tpl>
	)
}
