import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useNavigate } from "react-router-dom"
import { faCog, faClone, faEdit, faFilePdf, faMagnifyingGlass, faNote, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Box } from '@mui/material';

import APICtx from '#api'
import CartCtx from './cart'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Dialog from '#Dialog'
import { DisplayGroup, DisplayUser } from '#Display'
import Search, { SearchText, SearchDateInt, SearchSelect } from '#Search'
import statusOpts from './status.js'
import QuoteOps from './QuoteOps'
import EditBar from './EditBar'

const EditQuote = ({ _id, handleClose }) => {
	const api = useContext(APICtx)
	const [ quote, setQuote ] = useState({})

	useEffect(() => {
		if(_id)
			api.call('quote/get/'+_id).then(setQuote)
		else
			setQuote({})
	}, [ _id ])

	const title = "Modifica informazioni " + (quote._registry?.number || '')
	return (
		<Dialog handleClose={handleClose} open={Boolean(_id)} title={title} width="md">
			<QuoteOps _id={_id} />
		</Dialog>
	)
}

const useTabDef = () => {
	const api = useContext(APICtx)
	const adminView = api.auth.chkAny([ 'area-manager', 'quote-supervisor' ])
	return useMemo(() => {
		const def = [
			{ label:"Numero", content:({ _registry }) => _registry.number + (_registry.revision ? ' rev'+_registry.revision : '') },
			{ label:"Data", type:'date', content:({ _registry }) => _registry.ts },
			{ label:"Sales area", content: (row) => row.salesArea ? <DisplayGroup gid={row.salesArea} /> : '-'},
			{ label:"Stato", content:({ _id, trader, status, lostOfferReason }) => {
				const show = adminView || trader===api.auth.uid
				if(show) {
					const statusCur = statusOpts.find(item => item.value===status)
					if(statusCur)
						return (
							<>
								<Box>
									<FontAwesomeIcon size="lg" icon={statusCur.icon} /> {statusCur.label}
								</Box>
								<Box>
									{ statusCur.lost && lostOfferReason }
								</Box>
							</>
						)
				}
				return '-'
			}},
			{ label:"Prevista accettazione", field:'excpectedAcceptanceDate', type:'date' },
			{ label:"Commerciale", content: (row) => <DisplayUser uid={row.trader} />},
			{ label:"Cliente", content:({ customer, referral }) => (
				<>
					<Typography>{customer?.label}</Typography>
					<Typography variant="subtitle2">{referral}</Typography>
				</>
			)},
			{ content:({ privateNotes }) => privateNotes && <FontAwesomeIcon size="lg" icon={faNote} /> },
			{ label:"Totale", type:'money', content:({ price }) => price.tot },
		]
		return def
	}, [ adminView ])
}

const srcStatusOpts = [
	{ value:'30-99', label:'Dal 30% al 75%' },
	{ value:'50-99', label:'Dal 50% al 75%' },
	{ value:'75-99', label:'Al 75%' },
	{ value:'100', label:'Solo accettati' },
	{ value:'0', label:'Solo persi' },
	{ value:'all', label:'Tutti' },
]

export default function QuoteArchive() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const navigate = useNavigate()
	const [ srcString, setSrcString ] = useState('')
	const [ srcDateInt, setSrcDateInt ] = useState('')
	const [ srcStatus, setSrcStatus ] = useState('30')
	const [ srcTrader, setSrcTrader ] = useState('')
	const [ editId, setEditId ] = useState(null)
	const [ ids, setIds ] = useState([])
	const [ lostOfferReasons, setLostOfferReasons ] = useState([])
	const [ srcTraderOpts, setSrcTraderOpts ] = useState([])
	const [ salesAreas, setSalesAreas ] = useState([])
	const [ srcSalesArea, setSrcSalesArea ] = useState('')
	const tabDef = useTabDef(lostOfferReasons)

	const handleEditClose = () => {
		setEditId(null)
		handleRefresh()
	}
	const handleDelete = ({ _id, _registry }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il preventivo "'+_registry.number+'"?',
			onConfirm: () => api.call('quote/del/'+_id).then(handleRefresh),
		})
	}
	const handleEditCart = ({ _id }) => cart.editQuote({ _id }).then(navigate('/quote/submit/cart'))
	const handleEditCartNew = ({ _id }) => cart.editQuote({ _id, asNew:true }).then(navigate('/quote/submit/cart'))
	const handlePreviewPdf = ({ _id }) => api.openFile({ tag:'quote/getPdf/'+_id })
	const handleDownloadPdf = ({ _id, _registry }) =>
		api.openFile({
			tag: 'quote/getPdf/'+_id,
			download: 'preventivo-' + _registry.number + (_registry.revision ? '-rev'+_registry.revision : '') + '.pdf',
		})
	useEffect(() => {
		api.auth.chk('quote-supervisor') && api.auth.usersAsOptions().then(opts => setSrcTraderOpts([{ label:'-- Tutti --', value:'' }].concat(opts)))
		api.call('lost-offer-reason/options').then(setLostOfferReasons)
	}, [])
	const handleRefresh = () => api.call('quote/list', { srcString, srcDateInt, srcStatus, srcSalesArea, srcTrader }).then(setIds)
	useEffect(() => {
		handleRefresh()
	}, [ srcString, srcDateInt, srcStatus, srcSalesArea, srcTrader ])
	useEffect(() => {
		api.auth.groupsAsOptions({ acl:'sales-area' }).then(opts => {
			const selectOpts = (opts.filter(opt => api.auth.groups.includes(opt.address)))
			const allOpt = api.auth.chk('quote-supervisor') ? '' : selectOpts.map(opt => opt.value).join('+')
			setSalesAreas([
				{ value:allOpt, label:'-- Tutte ---' },
				...selectOpts,
			])
			setSrcSalesArea(allOpt)
		})
	}, [])

	const getRow = _id => api.call('quote/get/'+_id)
	const defBtns = [
		{ icon:faEdit, label:'Modifica preventivo', onClick:handleEditCart },
		{ icon:faClone, label:'Modifica come nuovo', onClick:handleEditCartNew },
		{ icon:faCog, label:'Gestisci', onClick:row => setEditId(row._id) },
		{ icon:faMagnifyingGlass, label:'Anteprima PDF', onClick:handlePreviewPdf },
		{ icon:faFilePdf, label:'Scarica PDF', onClick:handleDownloadPdf },
	]
	if(api.chkAuth('quote-supervisor'))
		defBtns.push({ icon:faTrash, label:"Elimina", onClick:handleDelete })

	const csvDef = [
		{ label:"Numero", content:({ _registry }) => _registry.number + (_registry.revision ? ' rev'+_registry.revision : '') },
		{ label:"Data", type:'date', content:({ _registry }) => _registry.ts },
		{ label:"Sales area", content: async ({ salesArea }) => {
			const group = salesArea ? await api.auth.appGroupGet(salesArea) : {}
			return group?.displayName || ''
		}},
		{ label:"Riferimento", field:'referral' },
		{ label:"Stato", content:({ status }) => statusOpts.find(cmp => cmp.value===status).label },
		{ label:"Prevista accettazione", field:'excpectedAcceptanceDate', type:'date' },
		{ label:"Autore", content: async ({ author }) => {
			const user = author ? await api.auth.appUserGet(author) : {}
			return user?.displayName || ''
		}},
		{ label:"Commerciale", content: async ({ trader }) => {
			const user = trader ? await api.auth.appUserGet(trader) : {}
			return user?.displayName || ''
		}},
		{ label:"Causa offerta persa", content:({ lostOfferReason }) => lostOfferReason },
		{ label:"Cliente", content:({ customer }) => customer?.label },
		{ label:"Note pubbliche", content:({ publicNotes }) => publicNotes },
		{ label:"Note interne", content:({ privateNotes }) => privateNotes },
		{ label:"Totale (Euro)", type:'number', content:({ price }) => price.tot },
	]

	return (
		<Tpl title='Archivio'>
			<EditBar />
			<Search>
				<SearchText label="Cerca numero / riferimento / cliente" name="src-text" onChange={setSrcString} />
				<SearchDateInt label="" name="src-text" onChange={setSrcDateInt} />
				<SearchSelect label="Filtro stato" name="src-status" onChange={setSrcStatus} options={srcStatusOpts} defaultValue={srcStatusOpts[0].value} />
				{ api.auth.chkAny([ 'quote-supervisor', 'area-manager' ]) && (
					<SearchSelect label="Sales area" name="src-salesarea" onChange={setSrcSalesArea} options={salesAreas} />
				)}
				{ api.auth.chk('quote-supervisor') && (
					<SearchSelect label="Commerciale" name="src-trader" onChange={setSrcTrader} options={srcTraderOpts} />
				)}
			</Search>
			<EditQuote _id={editId} handleClose={handleEditClose} />
			<Table def={tabDef} csvDef={csvDef} csvName="offerte" defBtns={defBtns} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
		</Tpl>
	)
}
