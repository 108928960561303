import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

export default function ContactEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' scheda'
	const formDef = {
		callSet: [ "contact/set", _id ],
		callGet: _id && "contact/get/"+_id,
		idRedirect: _id => '/contact/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/contact/list">
			<Form {...formDef}>
				<Input label="Denominazione" name="label" required />
				<Input label="Partita IVA" name="vat" required />
				<Input label="Metodo di pagamento" name="paymentMethod" />
			</Form>
		</Tpl>
	)
}