import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputNumber, Checkbox } from '#Form'
import Breadcrumbs from './Breadcrumbs'
import ProductBinds from './ProductBinds'

export default function QuoteAccessoryEdit() {
	const { _id } = useParams()

	const title = _id ? 'Modifica accessorio' : 'Crea accessorio'
	const formDef = {
		callSet: [ "accessory/set", _id ],
		callGet: _id && "accessory/get/"+_id,
		idRedirect: _id => '/quote/accessory/edit/'+_id,
	}
	return (
		<Tpl title={title}>
			<Breadcrumbs accessory={_id} isEdit />
			<Form {...formDef}>
				<Input label="Nome" name="name" required />
				<Input label="Codice" name="code" />
				<Input label="Descrizione" name="description" required multiline />
				<InputNumber label="Prezzo" name="price" suffix="€" />
				<Checkbox label="Attivo?" name="active" />
			</Form>
			{ Boolean(_id) && <ProductBinds accessory={_id} rw /> }
		</Tpl>
	)
}