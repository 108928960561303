import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, Select, Checkbox } from '#Form'
import Breadcrumbs from './Breadcrumbs'

export default function QuoteCatEdit() {
	const api = useContext(APICtx)
	const [ parentsOpts, setParentsOpts ] = useState([])
	const { _id } = useParams()

	const handleRefresh = () => {
		api.call('category/parentsOpts').then(setParentsOpts)
	}
	useEffect(handleRefresh, [ api ])
	const title = _id ? 'Modifica categoria' : 'Crea categoria'
	const formDef = {
		callSet: [ "category/set", _id ],
		callGet: _id && "category/get/"+_id,
		idRedirect: _id => '/quote/category/edit/'+_id,
	}
	return (
		<Tpl title={title}>
			<Breadcrumbs category={_id} isEdit />
			<Form {...formDef}>
				<Input label="Nome categoria" name="name" required />
				<Select label="Categoria genitore" name="parent" options={parentsOpts} required emptyLabel="-- nessuna categoria superiore --" />
				<Checkbox label="Attivo?" name="active" />
			</Form>
		</Tpl>
	)
}