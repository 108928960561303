import React, { useState, useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { InputNumber } from '#Form'
import Table from '#Table'
import Breadcrumbs from './Breadcrumbs'

export default function QuoteCatUpdPrices() {
	const api = useContext(APICtx)
	const { category } = useParams()
	const [ data, setData ] = useState({ deltaPerc:0 })
	const [ products, setProducts ] = useState([])

	const handleRefresh = () => api.call('product/list', { fullTree:true, category }).then(setProducts)
	useEffect(() => { handleRefresh() }, [])

	const formDef = {
		callSet: [ "category/update-prices", category ],
		data, setData,
		onSave: handleRefresh,
		submitLabel: 'Aggiorna prezzi',
	}

	const calcPerc = data.deltaPerc ? (100 + data.deltaPerc/10000) : 100
	const calcPrice = price => Math.round(price*calcPerc/(100*10000))*10000

	const getRow = _id => api.call('product/get/'+_id)
	const tabDef = [
		{ label:"Nome prodotto", content:({ name, active }) => name + (active ? '' : ' [NON ATTIVO]') },
		{ label:"Prezzo attuale", type:'money', content:({ price }) => price },
		{ label:"Prezzo finale", type:'money', content:({ price }) => calcPrice(price) },
	]

	return (
		<Tpl title="Aggiorna prezzi">
			<Breadcrumbs category={category} isUpdPrices />
			<Form {...formDef}>
				<InputNumber suffix="%" label="Variazione prezzo" name="deltaPerc" required />
			</Form>
			<Table def={tabDef} ids={products} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
		</Tpl>
	)
}