import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { faMagnifyingGlass, faFilePdf } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import SubmitStepper from './Stepper'
import Button from '#Button';

export default function QuoteArchive() {
	const api = useContext(APICtx)
	const { _id } = useParams()
	const [ quote, setQuote ] = useState({})

	useEffect(() => {
		api.call('quote/get/'+_id).then(setQuote)
	}, [ api, _id ])
	// const handlePdf = () => api.openFile('quote/getPdf/'+_id)
	const handlePreviewPdf = () => api.openFile({ tag:'quote/getPdf/'+_id })
	const handleDownloadPdf = () => api.openFile({ tag:'quote/getPdf/'+_id, download:'preventivo-'+quote?._registry.number+'.pdf' })
	
	return (
		<Tpl title='Preventivo completato'>
			<SubmitStepper step="done" />
			<Typography variant="subtitle1" gutterBottom component="div">Preventivo registrato col numero</Typography>
			<Typography variant="h5" gutterBottom component="div">{quote?._registry?.number}</Typography>
			<Box>
				<Button label="Visualizza PDF" icon={faMagnifyingGlass} onClick={handlePreviewPdf} />
			</Box>
			<Box sx={{ mt:1 }}>
				<Button label="Scarica PDF" icon={faFilePdf} onClick={handleDownloadPdf} />
			</Box>
		</Tpl>
	)
}