import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import Form, { Input, Select, InputDate } from '#Form'
import statusOpts from './status.js'

const evalStatus = quoteStatus => quoteStatus && statusOpts.find(status => status.value === quoteStatus)

export default function QuoteOps({ _id }) {
	const api = useContext(APICtx)
	const [ quote, setQuote ] = useState({})
	const [ isQuoteLost, setIsQuoteLost ] = useState(true)
	const [ lostOfferReasons, setLostOfferReasons ] = useState([])
	const [ salesAreas, setSalesAreas ] = useState([])
	
	useEffect(() => {
		api.call('lost-offer-reason/options').then(setLostOfferReasons)
		api.auth.groupsAsOptions({ acl:'sales-area' }).then(opts => {
			setSalesAreas(opts.filter(opt => api.auth.groups.includes(opt.address)))
		})
	}, [])

	const formDef = {
		callSet: [ "quote/setOpts", _id ],
		callGet: _id && "quote/get/"+_id,
		onLoad: data => {
			setIsQuoteLost(evalStatus(data?.status).lost)
			setQuote(data)
		},
	}

	const areaAdmin = api.auth.chk('area-manager') && salesAreas.find(area => area.value===quote.salesArea)
	const curStatus = quote.status && statusOpts.find(status => status.value===quote.status)
	const statusEditable = !isQuoteLost || areaAdmin
	return (
		<Form {...formDef}>
			<Select
				label="Stato"
				name="status"
				options={statusOpts}
				required
				onChange={status => setQuote({ ...quote, status })}
				disabled={!statusEditable}
			/>
			<Select
				label="Ragione offerta persa"
				name="lostOfferReason"
				options={lostOfferReasons}
				required
				emptyLabel="-- Seleziona una ragione --"
				disabled={!statusEditable || !curStatus?.lost}
			/>
			<Select label="Sales area" name="salesArea" options={salesAreas} required disabled={!areaAdmin} />
			<InputDate label="Data prevista accettazione" name="excpectedAcceptanceDate" required={!curStatus?.lost} />
			<Input label="Note interne" name="privateNotes" multiline />
		</Form>
	)
}
