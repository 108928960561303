import React, { useState, useContext, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { faEye, faLink, faUnlink, faCartPlus } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import CartCtx from './cart'
import Form, { Autocomplete } from '#Form'
import Table from '#Table'

const tabDef = [
	{ label:"Nome", content:({ name, active }) => name + (active ? '' : ' [NON ATTIVO]') },
	{ label:"Prezzo", type:'money', content:({ price }) => price },
]

const scanOptions = async (api, filter, callPrefix) => {
	const scan = await api.call(callPrefix+'/list', { srcString:filter, fullTree:true })
	return Promise.all(
		scan.map(async _id => {
			const { name } = await api.call(callPrefix+'/get/'+_id)
			return { label:name, value:_id }
		})
	)
}

export default function ProductBinds({ product, accessory, rw=false }) {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const [ ids, setIds ] = useState([])
	
	const getOptions = filter => scanOptions(api, filter, accessory ? 'product' : 'accessory')
	const handleLink = async ({ item }) => {
		if(item) {
			const prodId = product || item
			const accId = accessory || item
			await api.call('product/bindAccessory/'+prodId, { accessory:accId })
			await handleRefresh()
		}

	}
	const handleUnlink = async ({ _id }) => {
		const prodId = product || _id
		const accId = accessory || _id
		await api.call('product/unbindAccessory/'+prodId, { accessory:accId })
		await handleRefresh()
	}
	const handleAddToCart = ({ _id }) => cart.chQuantity({ product:_id, delta:1 })
	const handleRefresh = async () => {
		if(product) {
			const { accessories=[] } = await api.call('product/get/'+product)
			const accessoriesAvailable = await Promise.all(
				accessories.map(accessory => api.call('accessory/list', { srcId:accessory }))
			)
			setIds(accessoriesAvailable.flat(1))
		}
		if(accessory) {
			const scan = await api.call('product/list', { fullTree:true, srcAccessory:accessory })
			setIds(scan)
		}
	}
	useEffect(() => { handleRefresh() }, [ product, accessory ])

	const getRow = async (_id) =>
		accessory ? api.call('product/get/'+_id) : api.call('accessory/get/'+_id)

	const defBtns = [
		{ icon:faEye, label:"Visualizza", linkTo:({ _id }) => "/quote/"+(accessory ? 'product' : 'accessory')+"/view/"+_id },
	]
	if(!rw && api.chkAuth('quote-trader'))
		defBtns.push({ icon:faCartPlus, label:"Aggiungi al carrello", onClick:handleAddToCart })
	rw && defBtns.push({ icon:faUnlink, label:"Scollega", onClick:handleUnlink })

	const title = accessory ? 'Prodotti collegati' : 'Accessori collegati'
	const formLabel = accessory ? 'Nuovo prodotto' : 'Nuovo accessorio'
	return (
		<>
			<Typography variant="h5" gutterBottom>{title}</Typography>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			{ Boolean(rw) && (
				<Form submitLabel="Collega" submitIcon={faLink} onSubmit={handleLink}>
					<Autocomplete name="item" label={formLabel} getOptions={getOptions} />
				</Form>
			)}
		</>
	)
}