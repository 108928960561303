import React, { useMemo } from 'react';
import { Routes, Route, useNavigate } from "react-router-dom";

import APICtx, { useApi } from '#api'
import Screen, { TplWSidebar as Tpl } from '#Template'
import { faShoppingCart } from '@fortawesome/pro-regular-svg-icons'
import CartCtx, { useCart } from './quote/cart'
import logoImg from './logo.png'

// import DashboardPage from './Dashboard'
import QuoteCatUpdPricesPage from './quote/CatUpdPrices'
import QuoteCataloguePage from './quote/Catalogue'
import QuoteCatEditPage from './quote/CatEdit'
import QuoteProductListPage from './quote/ProductList'
import QuoteProductViewPage from './quote/ProductView'
import QuoteProductEditPage from './quote/ProductEdit'
import QuoteAccessoryListPage from './quote/AccessoryList'
import QuoteAccessoryViewPage from './quote/AccessoryView'
import QuoteAccessoryEditPage from './quote/AccessoryEdit'
import QuoteArchivePage from './quote/Archive'
import QuoteStatsPage from './quote/Stats'
import QuoteSubmitCartPage from './quote/submit/Cart'
import QuoteSubmitContactPage from './quote/submit/Contact'
import QuoteSubmitDiscountPage from './quote/submit/Discount'
import QuoteSubmitDonePage from './quote/submit/Done'
import ContactListPage from './contact/List'
import ContactEditPage from './contact/Edit'
import PaymentMethodListPage from './paymentMethod/List'
import PaymentMethodEditPage from './paymentMethod/Edit'
import LostOfferReasonListPage from './lostOfferReason/List'
import LostOfferReasonEditPage from './lostOfferReason/Edit'
import DatasheetListPage from './datasheet/List'

const Router = () => (
	<Routes>
		<Route path="/quote/category/update-prices/:category" element={<QuoteCatUpdPricesPage />} />
		<Route path="/quote/catalogue/:category" element={<QuoteCataloguePage />} />
		<Route path="/quote/catalogue" element={<QuoteCataloguePage />} />
		<Route path="/quote/category/edit/:_id" element={<QuoteCatEditPage />} />
		<Route path="/quote/category/edit" element={<QuoteCatEditPage />} />
		
		<Route path="/quote/product/list" element={<QuoteProductListPage />} />
		<Route path="/quote/product/view/:_id" element={<QuoteProductViewPage />} />
		<Route path="/quote/product/edit/:_id" element={<QuoteProductEditPage />} />
		<Route path="/quote/product/edit" element={<QuoteProductEditPage />} />

		<Route path="/quote/accessory/list" element={<QuoteAccessoryListPage />} />
		<Route path="/quote/accessory/edit/:_id" element={<QuoteAccessoryEditPage />} />
		<Route path="/quote/accessory/edit" element={<QuoteAccessoryEditPage />} />
		<Route path="/quote/accessory/view/:_id" element={<QuoteAccessoryViewPage />} />

		<Route path="/quote/archive" element={<QuoteArchivePage />} />
		<Route path="/quote/stats" element={<QuoteStatsPage />} />

		<Route path="/quote/submit/cart" element={<QuoteSubmitCartPage />} />
		<Route path="/quote/submit/contact" element={<QuoteSubmitContactPage />} />
		<Route path="/quote/submit/discount" element={<QuoteSubmitDiscountPage />} />
		<Route path="/quote/submit/done/:_id" element={<QuoteSubmitDonePage />} />
		
		<Route path="/contact/edit/:_id" element={<ContactEditPage />} />
		<Route path="/contact/edit" element={<ContactEditPage />} />
		<Route path="/contact/list" element={<ContactListPage />} />

		<Route path="/payment-method/edit/:_id" element={<PaymentMethodEditPage />} />
		<Route path="/payment-method/edit" element={<PaymentMethodEditPage />} />
		<Route path="/payment-method/list" element={<PaymentMethodListPage />} />

		<Route path="/lost-offer-reason/edit/:_id" element={<LostOfferReasonEditPage />} />
		<Route path="/lost-offer-reason/edit" element={<LostOfferReasonEditPage />} />
		<Route path="/lost-offer-reason/list" element={<LostOfferReasonListPage />} />
		
		<Route path="/datasheet/list" element={<DatasheetListPage />} />
		
		<Route path="/" element={<QuoteCataloguePage />} />
	</Routes>
)

const Blank = () => (
	<Tpl title="AHT Italy" />
)

const useTools = (api, cart) => useMemo(() => {
	const tools = []
	
	if(api.chkAuth('quote-trader'))
		tools.push({
			icon: faShoppingCart,
			badgeContent: cart.length,
			badgeColor: 'info',
			linkTo: "/quote/submit/cart",
			tooltip: "Carrello",
		})
	return tools
}, [ api, cart ])

const useMenu = (api) => useMemo(() => {
	const menu = []
	if(api.auth.chk())
		menu.push(
			{ "label":"Catalogo", "linkTo":"/quote/catalogue" },
			{ "label":"Lista prodotti", "linkTo":"/quote/product/list" },
		)
	if(api.chkAuthAny([ 'quote-trader', 'quote-supervisor' ]))
		menu.push({ "label":"Archivio", "linkTo":"/quote/archive" })
	if(api.chkAuthAny([ 'area-manager', 'quote-supervisor' ]))
		menu.push(
			{ "label":"Statistiche", "linkTo":"/quote/stats" },
		)
	if(api.chkAuth('quote-editor' ))
		menu.push(
			{ "label":"Metodi di pagamento", "linkTo":"/payment-method/list" },
			{ "label":"Causali offerta persa", "linkTo":"/lost-offer-reason/list" },
			{ "label":"Schede tecniche", "linkTo":"/datasheet/list" },
		)
	if(api.chkAuth())
		menu.push({ "label":"Rubrica", "linkTo":"/contact/list" })
	return menu
}, [ api ])

export default function App() {
	const api = useApi()
	const cart = useCart(api)
	const tools = useTools(api, cart)
	const menu = useMenu(api)

	//FIXME: at first load I'm always not auth so I'm always redirected to SSO
	// if(!api.auth.chk())
	// 	api.auth.login()

	return (
		<APICtx.Provider value={api}>
    	<CartCtx.Provider value={cart}>
			<Screen tools={tools} toolAuth menu={menu} logo={logoImg}>
				{api.chkAuth() ? <Router /> : <Blank />}
			</Screen>
		</CartCtx.Provider>
		</APICtx.Provider>
	)
}