import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
// import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';

import APICtx from '#api'
import CartCtx from '../cart'
import { TplWSidebar as Tpl } from '#Template'
import SubmitStepper from './Stepper'
import Form, { Input, Select, Autocomplete } from '#Form'

export default function QuoteArchive() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const [ data, setData ] = useState({})
	const [ contact, setContact ] = useState(null)
	const [ paymentMethods, setPaymentMethods ] = useState([])
	const [ salesAreas, setSalesAreas ] = useState([])
	const [ srcTraderOpts, setSrcTraderOpts ] = useState([])
	const navigate = useNavigate()

	useEffect(() => {
		api.auth.chk('quote-dataentry') && api.auth.usersAsOptions({ acl:'quote-assignable' }).then(setSrcTraderOpts)
	}, [])
	const handleRefresh = () => {
		api.call('payment-method/options').then(setPaymentMethods)
		api.auth.groupsAsOptions({ acl:'sales-area' }).then(opts => {
			setSalesAreas(opts.filter(opt => api.auth.groups.includes(opt.address)))
		})
	}
	useEffect(() => { handleRefresh() }, [])
	useEffect(() => {
		if(!cart.length)
			navigate('/quote/submit/cart', { replace:true })
	}, [ cart.length ])

	const setDataWrapped = newData => {
		const oldCustomerId = data?.customer?._id
		const newCustomerId = newData?.customer?._id ? (typeof(newData.customer._id)==='string' ? newData.customer._id : newData.customer._id.value) : null
		if(newCustomerId && contact?._id!==newCustomerId) {
			api.call('contact/get/'+newCustomerId).then(contactData => {
				newData.customer.label = contactData.label
				newData.customer.vat = contactData.vat
				if(contactData.paymentMethod) {
					newData.customer.paymentMethod = contactData.paymentMethod
				}
				else if(oldCustomerId) {
					newData.customer.paymentMethod = ''
				}
				setContact(contactData)
				setData(newData)
			})
		}
		else {
			if(oldCustomerId && !newCustomerId) {
				newData.customer.label = ''
				newData.customer.vat = ''
				newData.customer.paymentMethod = ''
				setContact(null)
			}
			setData(newData)
		}
	}
	
	const formDef = {
		callSet: "quote/submit/contact",
		callGet: "quote/cur/get",
		submitLabel: 'Salva e prosegui',
		idRedirect: () => '/quote/submit/discount',
		data, setData:setDataWrapped,
	}
	const customerOpts = srcString => api.call('contact/options', { srcString })
	const defaultSalesArea = (api.auth.groups.length && salesAreas.find(opt => api.auth.groups.includes(opt.address))?.value) || null
	return (
		<Tpl title='Selezione cliente'>
			<SubmitStepper step="contact" />
			<Form {...formDef}>
				<Autocomplete label="Rubrica clienti" name="customer._id" required getOptions={customerOpts} />
				<Input label="Denominazione" name="customer.label" required disabled={Boolean(data?.customer?._id)} />
				<Input label="Partita IVA" name="customer.vat" required disabled={Boolean(data?.customer?._id)} />
				{ Boolean(contact?.paymentMethod) ? (
					<Input label="Metodo di pagamento" name="customer.paymentMethod" required disabled />
				) : (
					<Select label="Metodo di pagamento" name="customer.paymentMethod" required emptyLabel="-- seleziona un metodo --" options={paymentMethods} />
				)}
				<Select label="Sales area" name="salesArea" required defaultValue={defaultSalesArea} options={salesAreas} />
				{ api.auth.chk('quote-dataentry') && (
					<Select label="Commerciale" name="trader" options={srcTraderOpts} emptyLabel="-- Seleziona un commerciale --" />
				)}
			</Form>
		</Tpl>
	)
}