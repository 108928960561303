import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"
import { Typography } from '@mui/material';

import { faEdit, faCopy, faTrash, faMoneyBillTrendUp, faCartPlus } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import CartCtx from './cart'
import { TplWSidebar as Tpl } from '#Template'
import Cards, { Card } from '#Cards'
import Breadcrumbs from './Breadcrumbs'
import CreateDial from './CreateDial'
import Table from '#Table'
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const IMAGES_BASE_PATH = '/catalogue-imgs/'

function Categories() {
	const api = useContext(APICtx)
	const [ list, setList ] = useState([])
	const { category=null } = useParams()

	const handleRefresh = () => { api.call('category/list', { parent:category }).then(setList) }
	useEffect(handleRefresh, [ category ])

	return !list.length ? null : (
		<Cards title="Categorie" sx={{ mb:1 }}>
			{ list.map(_id => <Category key={_id} _id={_id} refreshList={handleRefresh} />)}
			{ !category && (
				<Card linkTo="/quote/accessory/list" image={IMAGES_BASE_PATH + "accessories.jpg"}>
					<Typography variant="h6">Accessori</Typography>
				</Card>
			)}
		</Cards>
	)
}

function Category({ _id, refreshList }) {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})

	const handleRefresh = () => { api.call('category/get/'+_id).then(setData) }
	const handleDelete = () => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la categoria "'+data.name+'"?',
			onConfirm: () => api.call('category/del/'+_id).then(refreshList),
		})
	}
	useEffect(handleRefresh, [])
	const actions = [
		{ icon:faEdit, tooltip:"Modifica", linkTo:"/quote/category/edit/"+_id },
		{ icon:faMoneyBillTrendUp, tooltip:"Varia prezzi", linkTo:"/quote/category/update-prices/"+_id },
		{ icon:faTrash, tooltip:"Elimina", onClick:() => handleDelete() },
	]

	const name = data?.name ? (data.name + (data.active ? '' : ' [NON ATTIVO]')) : ''
	return (
		<Card linkTo={"/quote/catalogue/"+_id} image={IMAGES_BASE_PATH + _id + ".jpg"} actions={api.chkAuth('quote-editor') && actions}>
			<Typography variant="h6">{name}</Typography>
		</Card>
	)
}

const productsTabDef = [
	{ label:"Nome", content:({ name, active }) => name + (active ? '' : ' [NON ATTIVO]') },
	{ label:"Prezzo", type:'money', content:({ price }) => price },
]

function Products() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const [ list, setList ] = useState([])
	const { category=null } = useParams()

	const handleAddToCart = ({ _id }) => cart.chQuantity({ product:_id, delta:1 })
	const handleClone = ({ _id }) => api.call('product/clone/'+_id).then(handleRefresh)
	const handleDelete = ({ _id, name }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il prodotto "'+name+'"?',
			onConfirm: () => api.call('product/del/'+_id).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('product/list', { category }).then(setList)
	useEffect(() => {
		handleRefresh()
	}, [ category ])

	const actions = [
		{ icon:faEye, label:"Visualizza", linkTo:({ _id }) => "/quote/product/view/"+_id },
	]
	if(api.chkAuth('quote-trader'))
		actions.push({ icon:faCartPlus, label:"Aggiungi al carrello", onClick:handleAddToCart })
	actions.push(
		{ icon:faEdit, label:"Modifica", linkTo:({ _id }) => "/quote/product/edit/"+_id },
		{ icon:faCopy, label:"Clona", onClick:handleClone },
		{ icon:faTrash, label:"Elimina", onClick:handleDelete },
	)

	const getRow = _id => api.call('product/get/'+_id)
	return list.length ? (
		<>
			<Typography variant="h5">Prodotti</Typography>
			<Table def={productsTabDef} defBtns={actions} ids={list} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
		</>
	) : null
	// return !list.length ? null : (
	// 	<Cards title="Prodotti">
	// 		{ list.map(_id => <Product key={_id} _id={_id} refreshList={handleRefresh} />)}
	// 	</Cards>
	// )
}

export default function QuoteCatalogue() {
	const { category=null } = useParams()

	return (
		<Tpl title="Crea preventivo">
			<Breadcrumbs category={category} />
			<Categories />
			<Products />
			<CreateDial />
		</Tpl>
	)
}