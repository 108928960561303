import React, { useState, useContext, useEffect } from 'react';
import { useParams, useNavigate } from "react-router-dom"

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import { faEdit, faCopy, faCartPlus } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import CartCtx from './cart'
import { TplWSidebar as Tpl } from '#Template'
import Button from '#Button'
import Breadcrumbs from './Breadcrumbs'
import CreateDial from './CreateDial'
import { DisplayNumber } from '#Display'
import ProductBinds from './ProductBinds'

export default function QuoteProductView() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const { _id } = useParams()
	const navigate = useNavigate()
	const [ data, setData ] = useState({})

	const handleClone = async () => {
		const idNew = await api.call('product/clone/'+_id)
		navigate("/quote/product/edit/"+idNew)
	}
	const handleAddToCart = () => cart.chQuantity({ product:_id, delta:1 })
	const handleRefresh = () => { api.call('product/get/'+_id).then(setData) }
	useEffect(handleRefresh, [ _id ])

	const name = data?.name ? (data.name + (data.active ? '' : ' [NON ATTIVO]')) : ''
	const rw = api.chkAuth('quote-editor')
	return (
		<Tpl title='Scheda prodotto'>
			<Breadcrumbs product={_id} />
			<Typography variant="h4" gutterBottom component="div">
				{ rw && (
					<>
						<Button icon={faEdit} linkTo={"/quote/product/edit/"+_id} />
						<Button icon={faCopy} onClick={handleClone} />
					</>
				)}
				{name}
			</Typography>
			{ Boolean(data.code) && (
				<Typography variant="subtitle1" gutterBottom component="div">
					Codice: {data.code}
				</Typography>
			)}

			<Box sx={{ mb:3 }}>
				{ Boolean(data.description) && data.description.split("\n").map(descriptionBlock => (
					<Typography variant="body1">
						{descriptionBlock}
					</Typography>
				))}
				{ Boolean(data.price) && (
					<Typography variant="h6" gutterBottom component="div" sx={{ mt:1 }}>
						Prezzo: <DisplayNumber suffix="€" value={data.price} />
					</Typography>
				)}
				{ api.chkAuth('quote-trader') && <Button icon={faCartPlus} label="Aggiungi al carrello" onClick={handleAddToCart} /> }
			</Box>

			<ProductBinds product={_id} />
			<CreateDial />
		</Tpl>
	)
}