import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'

import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'

const tabDef = [
	{ label:"Definizione", field:'label' },
]

export default function PaymentMethodList() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare il metodo "'+label+'"?',
			onConfirm: () => api.call('payment-method/del/'+_id).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('payment-method/list').then(setIds)
	useEffect(() => { handleRefresh() }, [])

	const getRow = _id => api.call('payment-method/get/'+_id)
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/payment-method/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Lista metodi di pagamento'>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} />
			<Fab label="Aggiungi metodo" icon={faPlus} linkTo="/payment-method/edit" />
		</Tpl>
	)
}