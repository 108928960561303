import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Form, { Input, InputNumber, Select, Checkbox } from '#Form'
import Breadcrumbs from './Breadcrumbs'
import ProductBinds from './ProductBinds'

export default function QuoteProductEdit() {
	const api = useContext(APICtx)
	const [ parentsOpts, setParentsOpts ] = useState([])
	const [ datasheets, setDatasheets ] = useState([])
	const { _id } = useParams()

	const handleRefresh = () => {
		api.call('category/parentsOpts').then(setParentsOpts)
		api.call('datasheet/list').then(items =>
			setDatasheets(items.map(item =>
				({ value:item })
			))
		)
	}
	useEffect(handleRefresh, [ api ])

	const title = _id ? 'Modifica prodotto' : 'Crea prodotto'
	const formDef = {
		callSet: [ "product/set", _id ],
		callGet: _id && "product/get/"+_id,
		idRedirect: _id => '/quote/product/edit/'+_id,
	}
	return (
		<Tpl title={title}>
			<Breadcrumbs product={_id} isEdit />
			<Form {...formDef}>
				<Input label="Nome" name="name" required />
				<Input label="Codice" name="code" />
				<Input label="Descrizione" name="description" required multiline />
				<InputNumber label="Prezzo" name="price" required suffix="€" />
				<Select label="Categoria" name="category" options={parentsOpts} emptyLabel="-- nessuna categoria --" />
				<Select label="Scheda tecnica" name="datasheet" options={datasheets} emptyLabel="-- nessuna scheda associata --" />
				<Checkbox label="Attivo?" name="active" />
			</Form>
			{ Boolean(_id) && <ProductBinds product={_id} rw /> }
		</Tpl>
	)
}