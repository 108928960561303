import React, { useState, useContext, useEffect } from 'react';
import { faEdit, faTrash, faEye, faCartPlus } from '@fortawesome/pro-regular-svg-icons'

import APICtx from '#api'
import CartCtx from './cart'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Search, { SearchText } from '#Search'
import CreateDial from './CreateDial'
import EditBar from './EditBar'
import Dialog from '#Dialog'

const PRODUCTS_SHORTMODE_LIMIT = 4

function Product({ _id }) {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	
	const handleRefresh = () => {  api.call('product/get/'+_id).then(setData) }
	useEffect(handleRefresh, [ _id ])

	return data?.name ? (data.name + (data.active ? '' : ' [NON ATTIVO]')) : ''
}

function Products({ _id:accessoryId }) {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ showProductsDiag, setShowProductsDiag ] = useState(false)
	
	const handleRefresh = () => {  api.call('product/list', {
		fullTree: true,
		srcAccessory: accessoryId,
	}).then(setIds) }
	useEffect(handleRefresh, [ accessoryId ])

	const hiddenProducts = ids.length > PRODUCTS_SHORTMODE_LIMIT ? ids.length-PRODUCTS_SHORTMODE_LIMIT : 0
	return (
		<ul>
			{ ids.slice(0, PRODUCTS_SHORTMODE_LIMIT).map(productId => (
				<li>
					<Product key={productId} _id={productId} />
				</li>
			))}
			{ Boolean(hiddenProducts) && (
				<li>
					<a href="#" onClick={() => setShowProductsDiag(true)}>(Altri {hiddenProducts})</a>
					<Dialog handleClose={() => setShowProductsDiag(false)} title="Elenco prodotti" open={showProductsDiag} width="md">
						<ul>
							{ ids.map(productId => (
								<li>
									<Product key={productId} _id={productId} />
								</li>
							))}
						</ul>
					</Dialog>
				</li>
			)}
		</ul>
	)
}

const tabDef = [
	{ label:"Nome", content:({ name, active }) => name + (active ? '' : ' [NON ATTIVO]') },
	{ label:"Prezzo", type:'money', content:({ price }) => price },
	{ label:"Prodotti collegati", content:row => <Products {...row} /> },
]

export default function QuoteAccessoryList() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const [ srcString, setSrcString ] = useState('')
	const [ ids, setIds ] = useState([])

	const handleRefresh = () => api.call('accessory/list', { srcString }).then(setIds)
	const handleAddToCart = ({ _id }) => cart.chQuantity({ product:_id, delta:1 })
	const handleDelete = ({ _id, name }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare l\'accessorio "'+name+'"?',
			onConfirm: () => api.call('accessory/del/'+_id).then(handleRefresh),
		})
	}
	useEffect(() => { handleRefresh() }, [ api, srcString ])

	const getRow = _id => api.call('accessory/get/'+_id)
	const defBtns = [
		{ icon:faEye, label:"Visualizza", linkTo:({ _id }) => "/quote/accessory/view/"+_id },
	]
	if(api.chkAuth('quote-trader'))
		defBtns.push({ icon:faCartPlus, label:"Aggiungi al carrello", onClick:handleAddToCart })
	if(api.chkAuth('quote-editor'))
		defBtns.push(
			{ icon:faEdit, label:"Modifica", linkTo:({ _id }) => "/quote/accessory/edit/"+_id },
			{ icon:faTrash, label:"Elimina", onClick:handleDelete },
		)

	return (
		<Tpl title='Elenco accessori'>
			<EditBar />
			<Search>
				<SearchText label="Cerca" name="src-text" onChange={setSrcString} />
			</Search>
			<Table def={tabDef} defBtns={defBtns} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
			<CreateDial />
		</Tpl>
	)
}