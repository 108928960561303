import React, { useState, useMemo, useEffect } from "react"

const CartCtx = React.createContext(null)
export default CartCtx

export function useCart(api) {
	const [ cart, setCart ] = useState([])
	const [ editSource, setEditSource ] = useState()

	const handleChQuantity = async p => {
		await api.call('cart/chQuantity', p)
		await handleRefresh()
	}
	const handleRefresh = async () => {
		const ids = await api.call('cart/list')
		await Promise.all(ids.map(_id =>
			api.call('cart/get/'+_id)
		)).then(setCart)
		const quote = await api.call('quote/cur/get') 
		setEditSource(quote?.editFromNumber || null)
	}
	useEffect(() => { handleRefresh() }, [ api.isReady ])

	const clear = async () => {
		await api.call('quote/cur/clear')
		await handleRefresh()
	}

	const editQuote = async ({ _id, asNew }) => {
		await api.call('quote/reopen', { _id, asNew })
		await handleRefresh()
	}

	const cartLength = useMemo(() =>
		cart.reduce((agg, cur) => agg + cur.quantity, 0),
	[ cart ])
	const cartQty = useMemo(() =>
		cart.reduce((agg, cur) => agg + ( (cur.product?._id && !cur.product?.type) ? cur.quantity : 0), 0),
	[ cart ])

	return {
		refresh: handleRefresh,
		list: cart,
		length: cartLength,
		quantity: cartQty,
		chQuantity: handleChQuantity,
		editSource,
		clear,
		editQuote,
	}
}