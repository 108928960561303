import React, { useState, useContext, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { TextField } from '@mui/material';

import APICtx from '#api'
import { DisplayNumber } from '#Display'
import CartCtx from '../cart'
import { TplWSidebar as Tpl } from '#Template'
import Button from '#Button'
import SubmitStepper from './Stepper'
import Form, { Input } from '#Form'
import FormAuto, { Input as InputAuto, InputNumber as InputNumberAuto } from '#FormAutosave'

import { faPlus, faMinus, faTrash } from '@fortawesome/pro-regular-svg-icons'

function ShippingPriceForm({ onUpdate }) {
	const formDef = {
		callSet: "quote/cur/set",
		callGet: "quote/cur/get",
		onLoad: data => onUpdate(data),
		onSubmit: data => onUpdate(data),
	}
	return (
		<FormAuto {...formDef}>
			<InputNumberAuto name="shippingPrice" label="Spese di spedizione" required suffix="€" />
		</FormAuto>
	)
}

function Notes() {
	const formDef = {
		callSet: "quote/submit/cart",
		callGet: "quote/cur/get",
		submitLabel: "Salva e prosegui",
		idRedirect: () => '/quote/submit/contact',
	}
	return (
		<Form {...formDef} sx={{ mt:2 }}>
			<Input label="Riferimento ordine" name="referral" />
			<Input label="Note interne di AHT" name="privateNotes" multiline />
			<Input label="Note da aggiungere al PDF del cliente" name="publicNotes" multiline />
		</Form>
	)
}

function CartRowArchive({ product, fieldQty, fieldTot }) {
	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
			<TableCell>{product.name}</TableCell>
			<TableCell align="right">
				<DisplayNumber suffix="€" value={product.price} />
			</TableCell>
			<TableCell align="right">{fieldQty}</TableCell>
			<TableCell align="right">
				<DisplayNumber suffix="€" value={fieldTot} />
			</TableCell>
		</TableRow>
	)
}

function CartRowCustom({ _id, fieldQty, fieldTot }) {
	const cart = useContext(CartCtx)
	const formDef = {
		callSet: "cart/set/"+_id,
		callGet: "cart/get/"+_id,
		onSubmit: cart.refresh,
	}
	return (
		<TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
				<TableCell sx={{ pt:0, pb:0 }}>
					<FormAuto {...formDef}>
						<InputAuto label="Nome" name="product.name" inputStyle="filled" />
					</FormAuto>
				</TableCell>
				<TableCell sx={{ pt:0, pb:0 }}>
					<FormAuto {...formDef}>
						<InputNumberAuto label="Prezzo" name="product.price" inputStyle="filled" suffix="€" />
					</FormAuto>
				</TableCell>
				<TableCell align="right">{fieldQty}</TableCell>
				<TableCell align="right">
					<DisplayNumber suffix="€" value={fieldTot} />
				</TableCell>
		</TableRow>
	)
}

function CartRow({ _id, product, quantity }) {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const [ tmpManual, setTmpManual ] = useState(quantity)

	useEffect(() => {
		setTmpManual(quantity)
	}, [ quantity ])

	const handleQtyPlus = () => api.call('cart/chQuantity', { _id, delta:1 }).then(cart.refresh)
	const handleQtyMinus = () => api.call('cart/chQuantity', { _id, delta:-1 }).then(cart.refresh)
	const handleQtyRemove = () => api.call('cart/chQuantity', { _id, set:0 }).then(cart.refresh)
	const handleQtyManual = () => {
		const newValue = tmpManual && tmpManual>0 ? tmpManual : 1
		newValue!==tmpManual && setTmpManual(newValue)
		api.call('cart/chQuantity', { _id, set:newValue }).then(cart.refresh)
	}

	const fieldQty = (
		<>
			<Button icon={faTrash} onClick={() => handleQtyRemove(_id)} />
			{ quantity>1 && <Button icon={faMinus} onClick={() => handleQtyMinus(_id)} /> }
			<TextField label="Quantità" variant="outlined" value={tmpManual} onBlur={handleQtyManual} onChange={e => setTmpManual(e.target.value)} />
			<Button icon={faPlus} onClick={() => handleQtyPlus(_id)} />
		</>
	)
	const fieldTot = product.price * quantity

	return product._id ?
		<CartRowArchive product={product} fieldQty={fieldQty} fieldTot={fieldTot} />
		:
		<CartRowCustom _id={_id} product={product} fieldQty={fieldQty} fieldTot={fieldTot} />
}

export default function QuoteArchive() {
	const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const [ quote, setQuote ] = useState({})

	const handleShippingUpdate = ({ shippingPrice }) =>
		shippingPrice && setQuote({ ...quote, shippingPrice })
	const handleAddCustom = () => api.call('cart/chQuantity', { set:1 }).then(cart.refresh)

	const shippingTot = (quote.shippingPrice || 0)
	const productsTot = cart.list.reduce((agg, cur) => agg + (cur.quantity * cur.product.price), 0)
	
	return (
		<Tpl title='Carrello'>
			<SubmitStepper step="cart" />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }}>
					<TableHead>
						<TableRow>
							<TableCell>Prodotto</TableCell>
							<TableCell align="right">Prezzo unitario</TableCell>
							<TableCell align="right">Quantità</TableCell>
							<TableCell align="right">Totale</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{cart.list.map(item => <CartRow key={item._id} {...item} /> )}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell>Spese di spedizione</TableCell>
							<TableCell align="right">
								<ShippingPriceForm onUpdate={handleShippingUpdate} />
							</TableCell>
							<TableCell align="right">-</TableCell>
							<TableCell align="right">
								<DisplayNumber suffix="€" value={shippingTot} />
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell>Totale</TableCell>
							<TableCell></TableCell>
							<TableCell></TableCell>
							<TableCell align="right">
								<DisplayNumber suffix="€" value={productsTot + shippingTot} />
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</TableContainer>
			<Box display="flex" justifyContent="right" sx={{ mt:1 }}>
				<Button label="Aggiungi riga personalizzata" onClick={handleAddCustom} sx={{ mr:2 }} />
			</Box>
			<Notes />
		</Tpl>
	)
}