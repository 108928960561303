import React, { useContext, useState, useEffect, Fragment } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper'

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Search, { SearchDateInt, SearchSelect } from '#Search'
import statusOpts from './status.js'
import { TableActions } from '#Table';
import { DisplayUser, DisplayNumber, useEvalUser, evalNumber } from '#Display'

const mkCsvData = async (evalUser, stats) => {
	const head = [ 'Commerciale' ]
	statusOpts.forEach(opt => {
		head.push(opt.label + ' - Importo')
		head.push(opt.label + ' - Nr')
	})
	const content = await Promise.all(
		Object.entries(stats).map(async ([statsTrader, statsRow]) => {
			const row = [ await evalUser(statsTrader) ]
			statusOpts.forEach(opt => {
				const priceTot = statsRow[opt.value]?.priceTot || 0
				row.push(evalNumber({ value:priceTot }))
				row.push(statsRow[opt.value]?._count || 0)
			})
			return row
		})
	)
	return [ head, ...content ]
}

const StatsRow = ({ trader, stats }) => {
	return (
		<TableRow
			sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
		>
			<TableCell component="th" scope="row">
				<DisplayUser uid={trader} />
			</TableCell>
			{ statusOpts.map(statusOpt => (
				<Fragment key={statusOpt.value}>
					<TableCell align="right">
						<DisplayNumber value={stats[statusOpt.value]?.priceTot || 0} suffix="€" />
					</TableCell>
					<TableCell align="left">{stats[statusOpt.value]?._count || 0}</TableCell>
				</Fragment>
			))}
		</TableRow>
	)
}

export default function QuoteStats() {
	const api = useContext(APICtx)
	const evalUser = useEvalUser()
	const [ stats, setStats ] = useState([])
	const [ salesAreas, setSalesAreas ] = useState([])
	const [ srcPeriod, setSrcPeriod ] = useState({})
	const [ srcSalesArea, setSrcSalesArea ] = useState('all')

	useEffect(() => {
		api.call('trader/stats', { srcPeriod, srcSalesArea }).then(setStats)
	}, [ srcPeriod, srcSalesArea ])
	useEffect(() => {
		api.auth.groupsAsOptions({ acl:'sales-area' }).then(opts => {
			const selectOpts = (opts.filter(opt => api.auth.groups.includes(opt.address)))
			const allOpt = selectOpts.map(opt => opt.value).join('+')
			setSalesAreas([
				{ value:allOpt, label:'-- Tutte ---' },
				...selectOpts,
			])
			setSrcSalesArea(allOpt)
		})
	}, [])

	return (
		<Tpl title='Statistiche preventivi'>
			<Search>
				<SearchDateInt label="Periodo" name="src-period" onChange={setSrcPeriod} />
				<SearchSelect label="Sales area" name="src-salesarea" onChange={setSrcSalesArea} options={salesAreas} />
			</Search>
			<TableActions mkCsvData={() => mkCsvData(evalUser, stats)} csvName="statistiche" />
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Commerciale</TableCell>
							{ statusOpts.map(statusOpt => (
								<Fragment key={statusOpt.value}>
									<TableCell align="right">{statusOpt.label}</TableCell>
									<TableCell align="left">Nr</TableCell>
								</Fragment>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{Object.entries(stats).map(([statsTrader, statsRow]) => (
							<StatsRow key={statsTrader} trader={statsTrader} stats={statsRow} />
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</Tpl>
	)
}