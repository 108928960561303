import React, { useContext } from 'react';
import Box from '@mui/material/Box';

import CartCtx from './cart'
import Button from '#Button';
import { Typography } from '@mui/material';

export default function EditBar() {
	const cart = useContext(CartCtx)

	const abortEdit = () => cart.clear()

	return !cart.editSource ? null : (
		<Box sx={{ display:"flex", justifyContent:"space-between", pb:1 }}>
			<Box sx={{ p:1, border:"1px solid red" }}>
				<Typography>Stai modificando il preventivo {cart.editSource}</Typography>				
			</Box>
			<Box>
				<Button label="Annulla modifica" onClick={abortEdit} />
			</Box>
		</Box>
	)
}