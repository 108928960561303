import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom"

import Box from '@mui/material/Box';
//import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import FormHelperText from '@mui/material/FormHelperText';
import InputAdornmentMui from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import FilledInput from '@mui/material/FilledInput';
import SelectMui from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faDice } from '@fortawesome/pro-regular-svg-icons'

import { Typography, CircularProgress, FormControlLabel } from '@mui/material';
import CheckboxMui from '@mui/material/Checkbox';
import AutocompleteMui from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// import APICtx from './api'
import StatusIcon from './StatusIcon'
import { FormCtx, useErrors, useCallFunction, mkCallPath, ErrorHelper } from './FormLib'

function IconStatus({ syncing, error, untouched=false }) {
	if(syncing)
		return <StatusIcon level="syncing" />
	else if(error)
		return <StatusIcon level="danger" />
	else if(!untouched)
		return <StatusIcon level="success" />
	else
		return null
}

// const packCallPath = def =>
// 	Array.isArray(def) ? def.filter(Boolean).join('/') : def

export default function Form({ callGet:callGetRaw, callSet:callSetRaw, children, idRedirect, extraData={}, sx={}, onLoad, onUpdate, onSubmit, onSave }) {
	// const callGet = packCallPath(callGetRaw)
	// const callSet = packCallPath(callSetRaw)
	// const api = useContext(APICtx)
	const callGet = useCallFunction(callGetRaw)
	const callSet = useCallFunction(callSetRaw)
	const navigate = useNavigate()
	const [ defaults, setDefaults ] = useState(null)
	const [ errors, setErrors ] = useState({})
	// const [ saveDone, setSaveDone ] = useState(false)
	const isReady = defaults!==null

	const handleRefresh = () => {
		// if(callGet) {
		// 	api.callRaw(callGet, extraData).then(ret => {
		// 		setDefaults(ret.data)
		// 		onLoad?.(ret.data)
		// 	})
		// }
		if(callGet)
			callGet(extraData).then(ret => {
				setDefaults(ret?.data || {})
				onLoad?.(ret?.data || {})
			})
		else {
			setDefaults({})
			onLoad?.({})
		}
	}
	// const handleSet = async (dataPart) => {
	// 	if(callSet) {
	// 		const callDest = callSet
	// 		const data = { ...extraData, ...dataPart }
	// 		return api.callRaw(callDest, data)
	// 	}
	// }
	const handleSet = async (dataPart) => {
		const submitData = { ...extraData, ...dataPart }
		let ret
		if(onSubmit)
			ret = await onSubmit(submitData)
		if(!ret && callSet)
			ret = await callSet(submitData)
		
		console.log('SAVE', submitData, ret)
		if(ret && typeof(ret)==='object')
			setErrors(ret)
		else {
			setErrors({})
			await onSave?.(submitData, ret)
			if(ret && idRedirect) {
				const pathRedirect = mkCallPath(await idRedirect(ret), true)
				pathRedirect && navigate(pathRedirect, { replace:true })
			}
			// setSaveDone(true)
		}
	}

	const ctx = {
		//coreMode: !Boolean(id),
		//defaults,
		set: async (key, value) => {
			const data = { [key]:value }
			console.log('XXX', key, value, data)
			if(callSet) {
				// const { error, message, body:_id, ...others } = await handleSet(data)
				// console.log('ERR', error, message, others)
				// if(_id && idRedirect) {
				// 	const pathRedirect = await idRedirect(_id)
				// 	pathRedirect && navigate(pathRedirect, { replace:true })
				// }

				// if(!error)
				// 	await onUpdate?.(data)
				// if(error)
				// 	return { error:Boolean(error), msg:message || 'Errore durante il salvataggio sul server'}
				// else
				// 	return { error:Boolean(error), msg:message  }
				handleSet(data)
			}
			else
				await onUpdate?.(data)
		},
		get: key => {
			const extract = (path, data) => {
				const step = path.shift()
				if(data?.[step])
					return path.length ? extract(path, data[step]) : data[step]
				return ''
			}
			if(defaults)
				return extract(key.split('.'), defaults)
			else
				return ''
		},
		errors,
	}

	// eslint-disable-next-line
	useEffect(handleRefresh, [])
	const handleSubmit = (e) => e.preventDefault()

	return (
		<Box component="form" onSubmit={handleSubmit} sx={{ p:1, ...sx }}>
			<FormCtx.Provider value={ctx}>
				{isReady && children}
			</FormCtx.Provider>
		</Box>
	)
}

function InputAdornment({ suffix, isSyncing, isUntouched, isChanged, error }) {
	const adornment = []
	suffix && adornment.push(suffix)

	if(!isUntouched && !isChanged)
		adornment.push(<IconStatus key="_status" syncing={isSyncing} error={error} />)

	return (
		<InputAdornmentMui position="end">{adornment}</InputAdornmentMui>
	)
}

function InputRaw({ type, name, required, disabled, label, defaultValue, suffix, multiline, valueSet, valueGet, valueFixer, inputStyle, ...extraProps }) {
	const [ value, setValue ] = useState('')
	const [ isUntouched, setUntouched ] = useState(true)
	const [ isChanged, setChanged ] = useState(false)
	const [ isSyncing, setSyncing ] = useState(false)
	const inputRef = useRef(null)

	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const handleChange = e => {
		setUntouched(false)
		setChanged(true)
		setValue(e.target.value)
	}
	const handleSet = () => {
		if(isChanged) {
			setChanged(false)
			setSyncing(true)
			const newValue = valueFixer ? valueFixer(value) : value
			valueFixer && setValue(newValue)
			valueSet(name, newValue).then(() => setSyncing(false))
		}
	}
	const handleFocus = e => {
		inputRef?.current.focus()
	}
	const handleBlur = e => {
		if (e.currentTarget.contains(e.relatedTarget)) {
			inputRef?.current.focus()
			//inputRef?.current.setSelectionRange(1, 3)
		}
		handleSet()
	}
	const handleKeyDown = event => event.key==='Enter' && handleSet()
	useEffect(() => {
		if(defaultValue===undefined)
			valueGet(name).then(value =>
				setValue(value===undefined ? '' : value)
			)
		else
			setValue(defaultValue)
		// eslint-disable-next-line
	}, [ defaultValue ])

	const propsInput = {
		required, disabled, label, value, error, type,
		onChange: handleChange,
		onKeyDown: handleKeyDown,
	}
	const propsLabel = { required, error }
	type==='date' && (propsLabel.shrink=true)
	const adornment = <InputAdornment suffix={suffix} error={error} isSyncing={isSyncing} isUntouched={isUntouched} isChanged={isChanged} />
	if(multiline) {
		const curLinesQty = value ? value.split("\n").length : 0
		propsInput.multiline = Boolean(multiline)
		propsInput.rows = curLinesQty < 3 ? 3 : curLinesQty
	}

	return (
		<FormControl sx={{ mb:1 }} variant="outlined" error={error} onFocus={handleFocus} onBlur={handleBlur} fullWidth>
			<InputLabel {...propsLabel}>{label}</InputLabel>
			{ inputStyle==='filled' ?
				<FilledInput inputRef={inputRef} {...propsInput} endAdornment={adornment} /> :
				<OutlinedInput inputRef={inputRef} {...propsInput} endAdornment={adornment} />
			}
			<ErrorHelper name={name} />
        </FormControl>
	)
}

export function Input(props) {
	const ctx = useContext(FormCtx)
	const valueSet = async (name, value) => ctx.set(name, value)
	const valueGet = async (name) => ctx.get(name)

	return <InputRaw {...props} type="text" valueSet={valueSet} valueGet={valueGet} />
}

function rndPass(lengthRaw) {
	const length = parseInt(lengthRaw)
	//const pwdChars="0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	const pwdChars="123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
	return Array(length).fill(pwdChars).map(x => x[Math.floor(Math.random() * x.length)]).join('')
}

export function Password({ suffix, randomLength=null, ...props }) {
	const [ showPassword, setShowPassword ] = useState(false)
	const [ defaultValue, setDefaultValue ] = useState('')

	const handleRandomize = () => {
		setShowPassword(true)
		setDefaultValue(rndPass(randomLength))
	}

	const suffixCtrl = (
		<>
			{suffix}
			<IconButton
				key="_passwordShow"
				aria-label="toggle password visibility"
				onClick={() => setShowPassword(prevState => !prevState)}
				edge={ randomLength ? 'end' : undefined}
			>
				<FontAwesomeIcon icon={ showPassword ? faEye : faEyeSlash } />
			</IconButton>
			{ randomLength && (
				<IconButton
					key="_passwordRnd"
					aria-label="randomize password"
					onClick={handleRandomize}
				>
					<FontAwesomeIcon icon={faDice} />
				</IconButton>
			)}
		</>
	)

	const ctx = useContext(FormCtx)
	const valueSet = async (name, value) => ctx.set(name, value)
	const valueGet = async () => ''

	return <InputRaw {...props} type={showPassword ? 'text' : 'password'} valueSet={valueSet} valueGet={valueGet} suffix={suffixCtrl} defaultValue={defaultValue} />
}

export function InputNumber({ decimals=2, decimalsSpace=4, ...props }) {
	const ctx = useContext(FormCtx)
	const multiplier = Math.pow(10, decimalsSpace)

	const valueSet = async (name, value) => {
		const calc = Math.round(value * multiplier)
		return ctx.set(name, calc)
	}
	const valueGet = async (name) => {
		const value = (await ctx.get(name))/multiplier
		return value.toFixed(decimals)
		//TODO se ho più decimali dei "decimals" li mostro lo stesso
	}
	const valueFixer = valueOld => {
		if(!valueOld)
			return ''
		const str = parseFloat(
				valueOld
					.replace(/ /g, '')
					.replace(/,/g, '.')
		).toFixed(decimals)
		//TODO se ho più decimali dei "decimals" li mostro lo stesso
		return str
	}
	return <InputRaw {...props} type="text" valueFixer={valueFixer} valueSet={valueSet} valueGet={valueGet} />
}
export function InputDate(props) {
	const ctx = useContext(FormCtx)
	const valueSet = (name, value) => ctx.set(name, value)
	const valueGet = (name) => ctx.get(name)

	return <InputRaw {...props} type="date" valueSet={valueSet} valueGet={valueGet} />
}

export function Autocomplete({ name, required, disabled, label, getOptions, defaultValue, suffix }) {
	const [ options, setOptions ] = useState(null)
	const [ syncing, setSyncing ] = useState(false)
	const [ open, setOpen ] = useState(false)
	const [ untouched, setUntouched ] = useState(true)
	const [ value, setValue ] = useState(null)
	// const [ hint, setHint ] = useState(null)
	// const [ error, setError ] = useState(false)
	// const [ helper, setHelper ] = useState(null)
	const ctx = useContext(FormCtx)
	
	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const opt2value = opt => (typeof(opt)==='object' && opt!==null) ? opt.value : opt
	const opt2label = opt => (typeof(opt)==='object' && opt!==null) ? (opt.label || opt.value) : opt

	const updateOptions = async hint => {
		setSyncing(true)
		const newOpts = await getOptions(hint)
		setOptions(newOpts)
		setSyncing(false)
	}

	const onOpen = () => {
		setOpen(true)
		if(options===null)
			updateOptions()
	}
	const onClose = () => setOpen(false)

	const handleChange = (event, inputValue) => {
		if(open)
			updateOptions(inputValue)
		else
			setOptions(null)
	}

	const handleSet = (event, option) => {
		const newValue = opt2value(option)
		setValue(option)
		setUntouched(false)

		setSyncing(true)
		ctx.set(name, newValue).then(() => {
			setSyncing(false)
			// setError(error)
			// setHelper(msg)
		})
	}
	useEffect(() => {
		const value = ctx.get(name)
		setValue( (value===undefined ? defaultValue : value) || null)
	}, [])

	const adornment = <IconStatus syncing={syncing} error={error} untouched={untouched} />
	const renderInput = params => (
		<TextField
			{...params}
			label={label}
			InputProps={{
				...params.InputProps,
				endAdornment: (
					<>
						{ adornment }
						{ params.InputProps.endAdornment }
					</>
				),
			}}
		/>
	)

	const setup = {
		value, required, disabled,
		open, onOpen, onClose,
		onInputChange: handleChange,
		onChange: handleSet,
		renderInput,
		options: options || [],
		loading: syncing,
		getOptionLabel: opt2label,
		isOptionEqualToValue: (option, cmp) => opt2value(option) === opt2value(cmp),
		filterOptions: x => x,
		noOptionsText: 'Nessuna scelta disponibile',
	}

	return (
		<FormControl sx={{ mb:1 }} variant="outlined" error={error} fullWidth>
			<AutocompleteMui
				fullWidth
				clearOnEscape
				autoHighlight
				{...setup}
			/>
			<ErrorHelper name={name} />
        </FormControl>
	)
}

export function Select({ name, required, disabled, label, options, defaultValue, onChange, addMissingValue, emptyLabel, sx }) {
	const ctx = useContext(FormCtx)
	const [ value, setValue ] = useState(null)
	// const [ error, setError ] = useState(false)
	const [ syncing, setSyncing ] = useState(false)
	const [ untouched, setUntouched ] = useState(true)

	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const optValid = Boolean(options.filter(option => option.value===value).length)

	const handleChange = (e) => {
		const newValue = e.target.value || null
		setUntouched(false)
		setValue(newValue)
		if(name) {
			setSyncing(true)
			ctx.set(name, newValue).then(() => {
				setSyncing(false)
				// setError(error)
				// setHelper(msg)
				!error && onChange?.(newValue)
			})
		}
	}

	const iconStatus = <IconStatus syncing={syncing} error={error} untouched={untouched} />

	const props = { required, disabled, label, error,
		onChange: handleChange,
		value: (optValid ? value : ( emptyLabel ? null : Boolean(options.length) && options[0].value )) || '',
	}

	let optsShow = options
	if(addMissingValue && !optValid && value)
		optsShow.push({ value })
	const items = optsShow.map((opt, optIdx) => (
		<MenuItem key={optIdx} value={opt.value || ''}>
			<Grid container direction="row" justifyContent="space-between">
				<Grid item>{opt.label || opt.value}</Grid>
				{ opt.value===value && <Grid item>{iconStatus}</Grid> }
			</Grid>
		</MenuItem>
	))

	useEffect(() => {
		const ret = ctx.get(name)
		setValue(ret===undefined ? (defaultValue || null) : ret)
		// eslint-disable-next-line
	}, [])

	const calcSx = sx || {}
	calcSx.minWidth = 120

	return (
		<FormControl sx={calcSx} disabled={disabled} fullWidth>
			<InputLabel required={required} error={error}>{label}</InputLabel>
			<SelectMui {...props}>
				{ emptyLabel && (
					<MenuItem value="">
						<Grid container direction="row" justifyContent="space-between">
							<Grid item>{emptyLabel}</Grid>
							{ !value && <Grid item>{iconStatus}</Grid> }
						</Grid>
					</MenuItem>
				)}
				{items}
			</SelectMui>
			<ErrorHelper name={name} />
		</FormControl>
	)
}

export function Checkbox({ label, name, defaultValue, onChange, disabled, required, value=1 }) {
	const ctx = useContext(FormCtx)
	const [ syncing, setSyncing ] = useState(false)
	// const [ untouched, setUntouched ] = useState(true)
	const [ isChecked, setChecked ] = useState(name ? ctx.get(name)===value : Boolean(defaultValue))
	// const [ error, setError ] = useState(null)
	// const classes = useStyles()
	// const [ helper, setHelper ] = useState(null)

	const errorMsg = useErrors(name)
	const error = Boolean(errorMsg)

	const handleChange = (e) => {
		const newValue = e.target.checked ? value : null
		if(name) {
			setSyncing(true)
			ctx.set(name, newValue).then(() => {
				setSyncing(false)
				// setError(error)
				// setHelper(msg)
				!error && onChange?.(newValue)
				!error && setChecked(newValue)
			})
		}
	}

	// const iconStatus = <IconStatus syncing={syncing} error={error} untouched={untouched} />

	if(required)
		label += '*'
	return (
		<FormControl fullWidth component="fieldset" disabled={disabled} error={Boolean(error)}>
			{ syncing ? (
				<div>
					<CircularProgress size={16} />
					<Typography>{label}</Typography>
				</div>
			) : (
				<FormControlLabel
					checked={Boolean(isChecked)}
					control={<CheckboxMui />}
					label={label}
					onChange={handleChange}
					disabled={Boolean(disabled)}
				/>
			)}
			<ErrorHelper name={name} />
		</FormControl>
	)
}