import React from 'react';
import { Link } from "react-router-dom"

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import EditBar from '../EditBar'

const steps = [
	{ tag:'cart', label:'Carrello', linkTo:'/quote/submit/cart' },
	{ tag:'contact', label:'Selezione cliente', linkTo:'/quote/submit/contact' },
	{ tag:'discount', label:'Conferma sconto', linkTo:'/quote/submit/discount' },
]

export default function SubmitStepper({ step }) {
	const isDone = step==='done'
	const activeStep = isDone ? steps.length : steps.findIndex(item => item.tag===step)
	return (
		<Box sx={{ width:'100%', mb:3 }}>
			<EditBar />
			<Stepper activeStep={activeStep}>
				{steps.map(({ tag, label, linkTo }, idx) => (
					<Step key={tag} disabled={isDone || activeStep<=idx}>
						<StepButton color="inherit" component={Link} to={linkTo}>
							{label}
						</StepButton>
					</Step>
				))}
			</Stepper>
		</Box>
	)
}