import React from 'react';
import { useParams } from "react-router-dom"

import { TplWSidebar as Tpl } from '#Template'
import Form, { Input } from '#Form'

export default function PaymentMethodEdit() {
	const { _id } = useParams()

	const title = (_id ? 'Modifica' : 'Crea') + ' scheda'
	const formDef = {
		callSet: [ "payment-method/set", _id ],
		callGet: _id && "payment-method/get/"+_id,
		idRedirect: _id => '/payment-method/edit/'+_id,
	}
	return (
		<Tpl title={title} backTo="/payment-method/list">
			<Form {...formDef}>
				<Input label="Definizione" name="label" required />
			</Form>
		</Tpl>
	)
}