import React, { useContext } from 'react';
import { faRefrigerator, faArchive, faToolbox } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import SpeedDial from '#SpeedDial'

export default function CreateDial() {
	const api = useContext(APICtx)

	if(!api.chkAuth('quote-editor'))
		return null
		
	return (
		<SpeedDial actions={[
			{ icon:faArchive, tooltip:'Crea categoria', linkTo:'/quote/category/edit' },
			{ icon:faToolbox, tooltip:'Crea accessorio', linkTo:'/quote/accessory/edit' },
			{ icon:faRefrigerator, tooltip:'Crea prodotto', linkTo:'/quote/product/edit' },
		]} />
	)
}