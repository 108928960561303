import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
// import Box from '@mui/material/Box';

// import APICtx from '#api'
import CartCtx from '../cart'
import { DisplayNumber } from '#Display'
import { TplWSidebar as Tpl } from '#Template'
// import Button from '#Button'
import SubmitStepper from './Stepper'
import Form, { InputNumber } from '#Form'

// function DiscountForm({ onUpdate }) {
// 	const formDef = {
// 		callSet: "quote/cur/set",
// 		callGet: "quote/cur/get",
// 		onUpdate,
// 		onLoad: onUpdate,
// 	}
// 	return (
// 		<Form {...formDef}>
// 			<InputNumber label="Sconto" name="discountRate" suffix="%" required inputStyle="filled" />
// 		</Form>
// 	)
// }

export default function QuoteArchive() {
	// const api = useContext(APICtx)
	const cart = useContext(CartCtx)
	const navigate = useNavigate()
	const [ quote, setQuote ] = useState({})
	const [ data, setData ] = useState({})

	const handleDiscountUpdate = ({ discountRate=0, ...oth }) =>
		setQuote({ ...quote, ...oth, discountRate })
	const handleClose = (data, _id) => {
		console.log('-----', data, _id)
		if(_id) {
			cart.refresh()
			navigate('/quote/submit/done/'+_id)
		}
	}
	const productsTot = cart.list.reduce((agg, cur) => agg + (cur.quantity * cur.product.price), 0) 
	const discountTot = - (quote.discountRate || 0) * productsTot / (10000 * 100)
	const shippingTot = (quote.shippingPrice || 0)

	const setDataWrapped = newData => {
		handleDiscountUpdate(newData)
		setData(newData)
	}

	useEffect(() => {
		if(!cart.length)
			navigate('/quote/submit/cart', { replace:true })
		if(quote.shippingPrice && !quote.customer?.label)
			navigate('/quote/submit/contact', { replace:true })
	}, [ cart.length, quote ])

	const formDef = {
		// callSet: "quote/cur/set",
		callSet: "quote/submit/discount",
		callGet: "quote/cur/get",
		// onUpdate: handleDiscountUpdate,
		// onLoad: handleDiscountUpdate,
		submitLabel: "Salva preventivo",
		onSave: handleClose,
		data, setData:setDataWrapped,
	}
	
	return (
		<Tpl title='Conferma sconto'>
			<SubmitStepper step="discount" />
			<Form {...formDef}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }}>
						<TableBody>
							<TableRow>
								<TableCell>Articoli</TableCell>
								<TableCell></TableCell>
								<TableCell align="right">
									<DisplayNumber suffix="€" value={productsTot} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell sx={{ pt:0, pb:0 }}>
									{/* <DiscountForm onUpdate={handleDiscountUpdate} /> */}
									<InputNumber label="Sconto" name="discountRate" suffix="%" required inputStyle="filled" />
								</TableCell>
								<TableCell></TableCell>
								<TableCell align="right">
									<DisplayNumber suffix="€" value={discountTot} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Spese di spedizione</TableCell>
								<TableCell></TableCell>
								<TableCell align="right">
									<DisplayNumber suffix="€" value={shippingTot} />
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Totale</TableCell>
								<TableCell></TableCell>
								<TableCell align="right">
									<DisplayNumber suffix="€" value={productsTot + shippingTot + discountTot} />
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</Form>
		</Tpl>
	)
}