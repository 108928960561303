import React, { useState, useContext, useEffect } from 'react';
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { faHome, faEdit, faRefrigerator, faArchive, faToolbox, faMoneyBillTrendUp } from '@fortawesome/pro-solid-svg-icons'

import APICtx from '#api'
import EditBar from './EditBar'

const calcCategories = async (api, category) => {
	const stack = []
	let catNav = category
	while(catNav) {
		const data = await api.call('category/get/'+catNav)
		stack.unshift({ label:data.name, icon:faArchive, linkTo:'/quote/catalogue/'+catNav })
		catNav = data.parent
	}
	return stack
}

const calcProduct = async (api, _id) => {
	const stack = []
	const data = await api.call('product/get/'+_id)
	if(data.category)
		stack.push(...await calcCategories(api, data.category))
	stack.push({ label:data.name, icon:faRefrigerator, linkTo:'/quote/product/view/'+_id })
	return stack
}

const calcAccessory = async (api, _id) => {
	const stack = []
	stack.push({ label:'Accessori', icon:faArchive, linkTo:'/quote/accessory/list' })
	const data = await api.call('accessory/get/'+_id)
	stack.push({ label:data.name, icon:faToolbox, linkTo:'/quote/accessory/view/'+_id })
	return stack
}

const calcStack = async ({ api, category, product, accessory, isEdit, isUpdPrices }) => {
	const stack = [
		{ label:'Home', icon:faHome, linkTo:'/quote/catalogue' },
	]
	if(product)
		stack.push(...await calcProduct(api, product))
	else if(accessory)
		stack.push(...await calcAccessory(api, accessory))
	else if(category)
		stack.push(...await calcCategories(api, category))

	if(isEdit)
		stack.push({ label:'Modifica', icon:faEdit })
	else if(isUpdPrices)
		stack.push({ label:'Aggiornamento prezzi', icon:faMoneyBillTrendUp })
	else if(stack.length)
		delete stack[stack.length-1].linkTo
	return stack
}

export default function CatalogueBreadcrumbs({ category, product, accessory, isEdit, isUpdPrices }) {
	const api = useContext(APICtx)
	const [ stack, setStack ] = useState([])

	const handleRefresh = () => { calcStack({ api, category, product, accessory, isEdit, isUpdPrices }).then(setStack) }
	useEffect(handleRefresh, [ api, category, product, accessory, isEdit, isUpdPrices ])

	return (
		<>
			<EditBar />
			<Breadcrumbs aria-label="breadcrumb" sx={{ mb:1 }}>
				{ stack.map((step, idx) => step.linkTo ? (
					<Link key={idx} underline="hover" to={step.linkTo} style={{ textDecoration:'none' }}>
						<Typography color="text.primary" sx={{ "&:hover": { textDecoration:'underline' } }}>
							<FontAwesomeIcon icon={step.icon} /> {step.label}
						</Typography>
					</Link> ) : (
					<Typography key={idx} color="primary"><FontAwesomeIcon icon={step.icon} /> {step.label}</Typography>
				))}
			</Breadcrumbs>
		</>
	)
}