import React, { useState, useContext, useEffect } from 'react';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import Table from '#Table'
import Fab from '#Fab'

import { faEdit, faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import Search, { SearchText } from '#Search'

const tabDef = [
	{ label:"Denominazione", content:({ label }) => label },
	{ label:"P.IVA", field:'vat' },
	{ label:"Metodo di pagamento", field:'paymentMethod' },
]

export default function ContactList() {
	const api = useContext(APICtx)
	const [ srcString, setSrcString ] = useState('')
	const [ ids, setIds ] = useState([])

	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler cancellare la scheda "'+label+'"?',
			onConfirm: () => api.call('contact/del/'+_id).then(handleRefresh),
		})
	}
	const handleRefresh = () => api.call('contact/list', { srcString }).then(setIds)
	useEffect(() => { handleRefresh() }, [ srcString ])

	const getRow = _id => api.call('contact/get/'+_id)
	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			linkTo: row => '/contact/edit/'+row._id,
		},
		{ icon:faTrash, label:'Elimina', onClick:handleDelete },
	]

	return (
		<Tpl title='Lista contatti'>
			<Search>
				<SearchText label="Cerca" name="src-str" onChange={setSrcString} />
			</Search>
			<Table def={tabDef} defBtns={api.chkAuth('contact-editor') && defBtns} ids={ids} getRow={getRow} pageSize={30} pageSizeOpts={[ 30, 50 ]} />
			{ api.chkAuth('contact-editor') && <Fab label="Aggiungi scheda" icon={faPlus} linkTo="/contact/edit" /> }
		</Tpl>
	)
}